import React from 'react'
import styled from 'styled-components'
import { media, colors } from '../../lib/style-utils'

/* this styled component can be extended in the component */
const RevealContainer = styled.div`
    [data-aos="reveal_image"]{
        opacity: 0;
        transform: translateY(20px);
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        transition-duration: 1.5s;
        position: relative;
        ::after{
            content: '';
            position: absolute;
            z-index: 30;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: ${colors.white};
        }
    }
    [data-aos="reveal_image"].aos-animate{
        opacity: 1;
        transform: translateY(0);
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        transition-duration: 1.5s;
        transition-delay: 0.4s;
        ::after{
            width: 0%;
            transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
            transition-duration: 1.5s;
            transition-delay: 0.4s;
        }
    }
    ${media.desktop`
        [data-aos="reveal_image"]{
            transform: translateY(100px);
        }
    `}
`

const RevealImage = (props) => {

        return (
            <RevealContainer className="reveal_container">
                <div 
                    className="reveal_container"
                    data-aos="reveal_image"
                    data-aos-offset={ props.dataOffset }
                    data-aos-delay={ props.dataDelay }
                >
                    {props.children}
                </div>
            </RevealContainer>
        )
}

export default RevealImage