import React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components'

import Layout from "../components/layout"
import ArticleSection from '../components/Article/ArticleDetail/ArticleDetailPage'
import SEO from "../components/seo"

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
`

const Blog = data => {

    // Collect data
    const detail = data.data.articleData
    const allArticles = data.data.allArticles

    // SEO
    let seo = {
      title: data.data.articleData.seo.title,
      description: data.data.articleData.seo.description,
      lang: data.data.articleData.language,
      image: data.data.articleData.seo.social.facebook.image && data.data.articleData.seo.social.facebook.image.url,
      imageTwitter: data.data.articleData.seo.social.twitter.image && data.data.articleData.seo.social.twitter.image.url,
      robots: data.data.articleData.seo.advanced.robots && data.data.articleData.seo.advanced.robots
    }

    // 1. Language switcher - Create slugs object for language switcher
    const slugs = {
      slugNl: '',
      slugEn: '',
    }
    
    // 2. Language switcher - Build slugs depending on language
    if (data.data.articleData.language == "en"){
      slugs.slugNl = 'artikels/'+data.data.articleTranslation.slug
      slugs.slugEn = 'articles/'+data.data.articleData.slug
    } else {
      slugs.slugNl = 'artikels/'+data.data.articleData.slug
      slugs.slugEn = 'articles/'+data.data.articleTranslation.slug
    }

    // console.warn("Location state: " + data.location.state.prevPath)

    return (
        <Layout form={true} data={data.data.site} location={data.location} slugs={slugs}>
          <SEO {...seo}/>
           <Container>
             <ArticleSection data={detail} allArticles={allArticles} location={data.location} prevLocation={data.location.pathname} />
           </Container>
      </Layout>
    )
}

export default Blog

export const query = graphql`
  query ($id: ID!, $siteId:Int, $siteTransId:Int) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    articleData:craftArticleArticleEntry(remoteId: {eq: $id}, siteId: {eq: $siteId}) {
        siteId
        id
        title
        slug
        language
        blogintro
        blogqoute
        blogtext1
        blogtext2
        blogtext3
        blogtext4
        blogtext5
        blogreadtime
        blogtext1NextToImage
        blogtext2NextToImage
        blogtext3NextToImage
        blogtext4NextToImage
        blogtext5NextToImage
        caseyoutube1
        caseyoutube2
        caseyoutube3
        caseyoutube4
        blogtiktok1
        blogtiktok2
        blogtiktok3
        blogtiktok4
        blogtiktok5
        dateCreated
        postDate
        bloginsta1{
          ... on craft_bloginsta1_blogInsta1_BlockType {
            id
            embedUrl
          }
        }
        bloginsta2{
          ... on craft_bloginsta2_bloginsta2_BlockType {
            id
            embedUrl
          }
        }
        bloginsta3{
          ... on craft_bloginsta3_bloginsta3_BlockType {
            id
            embedCode
          }
        }
        bloginsta4{
          ... on craft_bloginsta4_bloginsta4_BlockType {
            id
            embedUrl
          }
        }
        author {
          name
        }
        seo {
          title
          description
          social {
            facebook {
              image {
                url
              }
            }
            twitter {
              image {
                url
              }
            }
          }
          advanced {
            robots
          }
        }
        categoriesServices {
          title
        }
        blogcoverimg {
          url
        }
        blogbannerimg {
          url
        }
        blogimage1 {
          url
        }
        blogbannerlink
        blogimage2 {
          url
        }
        blogbannerlink
        blogimage3 {
          url
        }
        blogimage4 {
          url
        }
        videoDesk {
          url
        }
        videoSmartphone {
          url
        }
    }
    articleTranslation:craftArticleArticleEntry(remoteId: {eq: $id}, siteId: {eq: $siteTransId}) {
      slug
    }
    cannesArticles: allCraftArticleArticleEntry( sort: {order:DESC, fields: postDate}, filter: {siteId: {eq:$siteId}, categoriesServices: {elemMatch: {slug: {eq: "cannes"}}}}) {
      nodes {
        title
        blogintro
        slug
        blogreadtime
        dateCreated
        language
        postDate
        categoriesServices {
          title
        }
        blogcoverimg{
          url
        }
      }
    }
    allArticles: allCraftArticleArticleEntry( sort: {order:DESC, fields: postDate}, filter: {siteId: {eq:$siteId}}) {
    nodes {
      title
      blogintro
      slug
      blogreadtime
      dateCreated
      language
      postDate
      categoriesServices {
        title
      }
      blogcoverimg{
        url
      }
    }
  }
}
`
