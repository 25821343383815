import React, {useEffect} from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'

import { colors, media } from '../../../lib/style-utils'
import { TikTokEmbed } from 'react-social-media-embed';

/* SectionExtended */
const EmbedWrapper = styled.div`
    padding: 2rem 0 !important;

    ${media.tablet`
        padding: 2rem 1rem !important;
    `}

    ${media.desktop`
        padding: 4rem 2rem !important;
    `}

    .rsme-embed blockquote{
        margin: 0 auto !important;
    }

    .rsme-tiktok-embed{
        width: 80%;
        margin: 0 auto !important;
    }

    iframe{
        margin: 0 auto !important;
    }
`


const TikTokFrame = (data) => {

        return (
            <div className="col-span-2 md:col-span-1 text-center">
                <EmbedWrapper>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <TikTokEmbed url={ data.data } />
                    </div>
                </EmbedWrapper>
            </div>
        )
}

export default TikTokFrame