import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl';
import ArticleCard from './ArticleCard'

import Section from '../../../styles/Section'

import { media , colors } from '../../../lib/style-utils'

const MainContainer = styled.div`
    background-color: ${colors.light_grey};
    padding: 0 1rem;
`

const SectionExtended = styled(Section)`
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 4rem;
    ${media.tablet`
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        padding-top: 4rem !important;
        padding-bottom: 6rem !important;
    `}
`

const Container = styled.div``

const TextWe = styled.span`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2.6rem;
    line-height: 3rem;
    font-weight: 800;
    margin-top: 2rem;
    padding-left: 0;
    color: ${colors.dark_grey};

    ${media.tablet`
        text-align: left;
        padding-left: unset;
        font-size: 2rem;
        line-height: 2.5rem;
    `};
    ${media.desktop`
        font-size: 2.3rem;
        line-height: 3rem;
    `};

    ${media.midDesktop`
        font-size: 2.5rem;
        line-height: 3rem;
    `};
`
const PrimaryButtonWrap = styled.div`
    width: 100%;
    display:flex;
    justify-content: center;
    margin-top: 1rem;
    ${media.tablet`
        justify-content: flex-end;
        margin-top: 2rem;
    `};
`
const PrimaryButton = styled(AniLink)`
    display: flex;
    margin-right: 0 !important;
    background-color: white;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    color: ${colors.dark_grey};
    padding: 0.6rem 2rem;
    transition: all 0.3s ease;
    ${media.tablet`
        background: ${colors.white};
        color: ${colors.dark_grey};
        transition: all 0.3s ease;
        width: auto;
        justify-content: flex-end;
        align-self: flex-end;
    `};
    :hover{
        transition: background 0.3s ease;
        border-color: orange;
        color: orange;
    }
`

const ArticleSection = ({data, title, tags, location}) => {

    const casesData = data
    const serviceTags =tags
    const serviceTag = serviceTags[0].title

    // Translations
    const intl = useIntl();
    const slugArtikels = intl.formatMessage({id: 'slugArtikels'});

    let caseDataFiltered = []

    return (
        <div>
            {
                casesData.map((caseSingle, i) => {
                    let caseSingleTags = caseSingle.categoriesServices
                    let checkTag = caseSingleTags.find(element => element.title === serviceTag)
                    
                    if(checkTag){
                        caseDataFiltered.push(caseSingle)
                    }

                    return null
                })
            }
            {caseDataFiltered[0] && 
                <MainContainer>
                    <SectionExtended>
                        <TextWe>
                            {title}
                        </TextWe>
                        <Container className="grid grid-cols-12 gap-0 md:gap-8 lg:gap-12 pt-8">
                            {
                                caseDataFiltered.map((caseSingle, i) => {
                                    if (i < 3){
                                        return <ArticleCard key={i} location={location} data={caseSingle}/>
                                    }
                                    return null
                                })
                            }
                        </Container>
                    </SectionExtended>
                </MainContainer>
            }
        </div>
   )
}

export default ArticleSection
