import React from 'react'
import parse from 'html-react-parser'
import styled from 'styled-components'
import moment from 'moment'

import AniLink from "gatsby-plugin-transition-link/AniLink"
import Section from '../../../styles/Section'
import Arrow from '../../General/Icons/Arrow'
import SocialIcons from '../../General/Social/Social'
import GlobalTextStyles from '../../General/Text/GlobalTextStyles'
import { FormattedMessage, useIntl } from 'react-intl';
import ArticleSection from './ArticleSection'
import YoutubeEmbed from '../../Case/CaseDetail/YoutubeEmbed';
import InstagramEmbed from '../../Case/CaseDetail/InstagramEmbed';
import TikTokEmbed from '../../Case/CaseDetail/TikTokEmbed';

import { fonts, colors, media } from '../../../lib/style-utils'

import RevealOpacity from '../../Animation/RevealOpacity'
import RevealImage from '../../Animation/RevealImage'
import ProfilePic from '../../../images/blog/author_logo.jpg'

/* SectionExtended */
const SectionExtended = styled(Section)`
    position: relative;
    padding-top: 0; 
    padding-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    .image_text{
        margin-bottom: 1rem;
    }

    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 2rem;
    `}
    ${media.desktop`
        width: 88%;
        max-width: 88%;
        margin: 0 auto;
    `}
`

const Container = styled.div`
    width: 100%;
`

const SectionText = styled.div`
    font-family: ${fonts.Georgia};
    width: 100%;
`
const TextBox = styled.div`
    font-family: ${fonts.Georgia};
    padding: 1rem 0 1rem 0;
    ${media.desktop`
        padding: 2rem 0 1rem 0;
    `}
`

const BlogBackLink = styled.div`
    padding: 0 0 1rem 0;
    width: 100%;
    texalign: left;
`

const BlogBackLinkInner = styled.div`
    font-weight: 500;
    color: ${colors.grey};
    transition: all 0.3s ease;
    svg{
        width: 20px;
        position: relative;
        top: -1px;
        display: inline;
        margin-right: 10px;
    }
    svg polyline{
        stroke: ${colors.grey};
        transition: all 0.3s ease;
    }
    a :hover svg polyline{
        stroke: orange;
        transition: all 0.3s ease;
    }
    a{
        font-style: italic;
    }
    a :hover{
        color: orange;
        transition: all 0.3s ease;
    }
`

const CaseOnlyimageContainer = styled.div`
    width: 100%;
    max-width: 60rem;
    margin: 1.5rem auto;
    ${media.desktop`
        width: 80%;
        max-width: 60rem;
        margin: 3rem auto;
    `}
`

const TextMain = styled.span`
    display: block;
    width: 100%;
    font-weight:800;
    font-size: 2rem;
    line-height: 2rem;
    align-items: left;
    text-align: left;
    ${media.phablet`
        font-size: 2rem;
        line-height: 2.5rem;
    `};

    ${media.tablet`
        font-size: 2rem;
        line-height: 3rem;
    `};
    ${media.desktop`
       font-size: 2.8rem;
       line-height: 3.5rem;
    `};

    ${media.midDesktop`
        font-size: 2.8rem;
        line-height: 3.5rem;
    `};
`
const Paragraph = styled.p`
    align-self: flex-start;
    font-size: 0.8rem;
    font-family:${fonts.Georgia};
    font-weight: 300;
    line-height: 1.3rem;
    color: ${colors.dark_grey};
    margin-bottom: unset;
    ${media.tablet`
        font-size: 0.7rem;
    `};
    ${media.midDesktop`
        font-size: 0.8rem;
    `};
`
const CaseContainer = styled.div`
    width: 100%;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    ${media.tablet`
        margin-top: 2rem;
        margin-bottom: 2rem;
    `};
`

const SplitImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const SplitText = styled.div`
    display: flex;
    align-items: center;
    ${media.desktop`
        padding: 0 2rem;
    `}
`

const FrameWrapper = styled.div`
    width: 100%;
    display: none;
    ${media.desktop`
        display: block;
    `}
`

const FrameInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 57%;
`

const Frame = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    iframe{
        width: 100%;
        height: 100%;
    }
`

const Banner = styled.img`
    margin: 0rem;
    margin-top: 0;
    
    ${media.tablet`
        width: 100%;
    `};
`

const ExternalLink = styled.a`
    display: inline-block;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${colors.orange};
        opacity: 0.2;
        z-index: 10;
        ${media.desktop`
            opacity: 0;
            transition: all 0.15s ease;
        `}
    }
    svg{
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        transform: scale(-1);
        transition: all 0.15s ease;
        z-index: 20;
        polyline{
            stroke: ${colors.white};
        }
        max-width: 2rem;
        ${media.tablet`
            max-width: 3rem;
        `}
        ${media.desktop`
            max-width: 4rem;
            opacity: 0;
        `}
    }
    &:hover{
        svg{
            opacity: 1;
            transition: all 0.15s ease;
        }
        &::after{
            opacity: 0.2;
            transition: all 0.15s ease;
        }
    }
`

const BannerSide = styled.img`
    margin-bottom: 2rem;
    margin-top: 2rem;
    
    ${media.desktop`
        width: 100%;
        max-width: 35rem;
        margin: 0 auto;
    `};
`

const ContentSection = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
`   
const TextContainer = styled.div`
    width: 100%;
    height: 100%;
    order: 1;
    ${media.tablet`
        order: 2;
    `};
`
const TextParagraph = styled(Paragraph)`
  
`
const Intro = styled.p`
    font-size: 1rem;
    line-height: 1.8rem;
    margin-top: 2rem;
    font-family: ${fonts.Montserrat};
    ${media.tablet`
        margin-top: 4rem;
    `}
    :first-letter {
        font-family: ${fonts.Montserrat}, arial;
        -webkit-initial-letter: 2 2;
        initial-letter: 2 2;
        color: ${colors.dark_grey};
        margin-right: 0.8rem;
        font-size: 2rem;
        line-height: 1.4rem;
        ${media.tablet`
            -webkit-initial-letter: 2 2;
            initial-letter: 2 2;
            font-size: 3rem;
            line-height: 1.8rem;
        `}
    }
`

const BlogContentContainer = styled.div``

const BlogTag = styled.span`
    display: inline-block;
    font-family: ${fonts.Montserrat};
    font-size: 0.5rem;
    line-height: 0.6rem;
    font-weight: 600;
    color: white;
    background-color: ${colors.grey};
    padding: 0.4rem 1rem;
    border-radius: 0.8rem;
    margin-top: 1rem;
    margin-right: 10px;
    ${media.phoneS`
        font-family: ${fonts.Montserrat};
        font-size: 0.6rem;
        line-height: 0.6rem;
    `};
    ${media.tablet`
        font-family: ${fonts.Montserrat};
        font-size: 0.6rem;
        font-weight: 600;
    `};
`
const QouteContainer = styled.div`
    padding: 2rem 0;
    font-family: ${fonts.Montserrat};
    font-size: 0.5rem;
    line-height: 0.6rem;
    font-weight: 500;
    color: ${colors.dark_grey};
    width: 100%;
    text-align: center;
`
const Qoute = styled.div`
    font-family: ${fonts.Montserrat};
    font-style: italic;
    font-size: 1.5rem;
    line-height:2rem;
    text-align: center;
    font-weight: 700;
    color: ${colors.dark_grey};
    width: 100%;
    ${media.phablet`
        font-size: 2rem;
        line-height:2.5rem;
    `};
    ${media.tablet`
        font-size: 3rem;
        line-height: 3.5rem;
    `};
`
const SocialMedia = styled.div`
    position: absolute;
    top: 0;
    right: 2rem;
    width: auto;
    padding-top: 2rem;
    display: none;
    svg{
        fill: orange;
    }
    ${media.midDesktop`
        display: block;
    `};
`
const BlogContentWrap = styled.div`
    margin-bottom: 0;
    padding-top: 2rem;
    ${media.tablet`
        position: relative;
    `}
`

const BlogContentContainerSmall = styled.div`
    ${media.desktop`
        width: 70%;
        margin: 0 auto 1rem auto;
    `}
`

const BlogBannerContainer = styled.div`
    min-height: calc(56vw - 4rem);
    ${media.desktop`
        width: 70%;
        margin: 0 auto 1rem auto;
        min-height: calc(31vw);
    `}
`

const AuthorContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2.5rem 0 1rem 0;
    ${media.phoneS`
        font-size: 0.7rem;
        font-weight: 500;
    `};
    ${media.tablet`
        margin: 3rem 0;
    `};
`
const AuthorContent = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1rem;
    ${media.phoneS`
        font-size: 0.7rem;
        font-weight: 500;
    `};
`
const AuthorName = styled.div`
    font-family: ${fonts.Montserrat};
    font-size: 0.7rem;
    line-height: 1rem;
    font-weight: 600;
    ${media.phoneS`
        font-size: 0.7rem;
        font-weight: 600;
    `};
    ${media.tablet`
        display: flex;
    `}
`

const ProfilePhoto = styled.img`
    width: 1.5rem;
    height: 1;
    clip-path: circle(50% at 50% 50%);
    margin-right: 0.5rem;
    ${media.tablet`
        display: block;
        margin-right: 0.5rem;
        font-size: 0.7rem;
        font-weight: 500;
        width: 2rem;
        height: 2rem;
    `};
`
const BlogIndex = styled.span`
    font-family: ${fonts.Montserrat};
    font-size: 0.7rem;
    font-weight: 500;
    color: ${colors.dark_grey};
`
const BlogDate = styled.span`
      margin: 0;
      ${media.tablet`
        display: inline-block;
        margin-right: 4px;
      `}
`

const ArticleDetailPage = data => {

    // FormattedMessage for title related articles
    const intl = useIntl();
    const relatedTitle = intl.formatMessage({id: 'relatedArticles'});

    // Article detail page data
    const detail = data.data
    // Article detail categories object array
    const detailCategoriesObjectArray = detail.categoriesServices
    // Article detail categories titles array
    const detailCategoriesArray = []
    detailCategoriesObjectArray.map(category=>{
        detailCategoriesArray.push(category.title)
    })
    // All articles data
    const allArticles = data.allArticles.nodes
    // Define filtered articles array
    const filteredArticles = []
    // Loop trough all articles
    allArticles.map(article=>{
        // Article single object array
        const articleCategoriesObjectArray = article.categoriesServices
        // Article single titles array
        const articleCategoriesArray = []
        articleCategoriesObjectArray.map(category=>{
            articleCategoriesArray.push(category.title)
        })
        // Check if categorie titles array equals some values of detail page article category title values
        const found = articleCategoriesArray.some(r=> detailCategoriesArray.includes(r))
        if(found){
            filteredArticles.push(article);
        }
    })

    let isCannes = detail.categoriesServices.some(element => element.title == 'Cannes')
    console.warn(data.prevLocation)

    let prevLocation = data.prevLocation;
    let tikTok = 'false';
    if(prevLocation){
        tikTok = data.prevLocation.includes('tiktok');
    }
    
    // Translated slug for the backlink
    let slug = 'articles'
    if (detail.language == "nl"){
        slug = 'artikels'
    }

    // Blog banner link
    const blogbannerLink = detail.blogbannerlink;

    return (
        <div className="grid grid-cols-12">
            <div className="col-span-12">
                <SectionExtended>
                    <RevealOpacity>
                        <SocialMedia id="social-bar">
                            <SocialIcons />
                        </SocialMedia>
                    </RevealOpacity>
                    <RevealOpacity>
                        <BlogContentWrap>
                            <BlogContentContainerSmall>
                                <RevealOpacity>
                                    <Container>
                                        <BlogBackLink>
                                            <BlogBackLinkInner>
                                                {
                                                    isCannes ? 
                                                    <AniLink
                                                        to={`/${detail.language}/cannes`}
                                                        cover direction="right" 
                                                        duration={1.2}
                                                        bg="orange"
                                                    >
                                                        <Arrow/>
                                                        <FormattedMessage id="navArticles"/>
                                                    </AniLink>
                                                    : tikTok ? 
                                                            <AniLink
                                                            to={`/${detail.language}/tiktok`}
                                                            cover direction="right" 
                                                            duration={1.2}
                                                            bg="orange"
                                                        >
                                                            <Arrow/>
                                                            <FormattedMessage id="navArticles"/>
                                                        </AniLink> 
                                                        :
                                                        <AniLink
                                                            to={`/${detail.language}/${slug}`}
                                                            cover direction="right" 
                                                            duration={1.2}
                                                            bg="orange"
                                                        >
                                                            <Arrow/>
                                                            <FormattedMessage id="navArticles"/>
                                                        </AniLink>
                                                } 
                                            </BlogBackLinkInner>
                                        </BlogBackLink>
                                    </Container>
                                    <Container>
                                        <SectionText>
                                            <TextBox> 
                                                <TextMain>
                                                    {detail.title}
                                                </TextMain>
                                                {
                                                    detail.categoriesServices.map(blogtag => {
                                                    if (!blogtag) return null
                                                        return (
                                                            <BlogTag>{blogtag.title}</BlogTag>
                                                        )
                                                    })
                                                }
                                            </TextBox>
                                        </SectionText>
                                    </Container>
                                </RevealOpacity>
                            </BlogContentContainerSmall>
                            {blogbannerLink && 
                            <BlogContentContainerSmall>
                                <FrameWrapper>
                                    <FrameInner>
                                        <Frame>
                                            <iframe src={blogbannerLink} frameborder="0" allowfullscreen=""></iframe>
                                        </Frame>
                                    </FrameInner>
                                </FrameWrapper>
                            </BlogContentContainerSmall>}
                            <BlogBannerContainer className={blogbannerLink ? "lg:hidden" : "block"}>
                                {detail.blogbannerimg[0] &&
                                    blogbannerLink
                                    ? <RevealImage dataOffset="200"><ExternalLink target="_blank" href={blogbannerLink}><Arrow/><Banner src={detail.blogbannerimg[0].url}/></ExternalLink></RevealImage>
                                    : <RevealImage dataOffset="200"><Banner src={detail.blogbannerimg[0].url}/></RevealImage>
                                }
                            </BlogBannerContainer>
                            <BlogContentContainerSmall>
                                <ContentSection>
                                    <Intro className="Intro">
                                        {detail.blogintro}
                                    </Intro>
                                </ContentSection>
                            </BlogContentContainerSmall>
                            {detail.blogqoute && 
                                <ContentSection>
                                    <QouteContainer>
                                        <Qoute>
                                            {detail.blogqoute}
                                        </Qoute>
                                    </QouteContainer>
                                </ContentSection>
                            }
                            <GlobalTextStyles>
                                
                            {detail.blogtext1 && detail.blogtext1NextToImage === false &&
                                <BlogContentContainerSmall>
                                    <BlogContentContainer>
                                        <TextContainer>
                                            <TextParagraph>
                                                {parse(detail.blogtext1)}
                                            </TextParagraph>
                                        </TextContainer>
                                    </BlogContentContainer>
                                </BlogContentContainerSmall>
                            }
                            
                            {detail.blogimage1[0] && detail.blogtext1NextToImage === false &&
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <RevealImage dataOffset="200">
                                            <Banner src={detail.blogimage1[0].url}/>
                                        </RevealImage>
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            }
                            {detail.caseyoutube1 ?
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <YoutubeEmbed data={detail.caseyoutube1} />
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            : null}
                            {detail.blogtiktok1 ?
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <TikTokEmbed data={detail.blogtiktok1} />
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            : null}
                            {detail.bloginsta1[0] ?
                                    <CaseOnlyimageContainer>
                                        <RevealImage dataOffset="100">
                                            <div className="grid grid-cols-2">
                                                {
                                                    detail.bloginsta1.map(insta => {
                                                        return <InstagramEmbed data={insta} />
                                                    })
                                                }
                                            </div>
                                        </RevealImage>
                                    </CaseOnlyimageContainer>
                                : null}
                            {detail.blogimage1[0] && detail.blogtext1NextToImage === true &&
                                <div className="grid grid-cols-2 image_text">
                                    <SplitImage className="col-span-2 lg:col-span-1">
                                        <CaseContainer>
                                            <RevealImage dataOffset="200">
                                                <BannerSide src={detail.blogimage1[0].url}/>
                                            </RevealImage>
                                        </CaseContainer>
                                    </SplitImage>
                                    <SplitText className="col-span-2 lg:col-span-1">
                                        <BlogContentContainer className="no_margin">
                                            <TextContainer>
                                                <TextParagraph>
                                                    {parse(detail.blogtext1)}
                                                </TextParagraph>
                                            </TextContainer>
                                        </BlogContentContainer> 
                                    </SplitText>
                                </div>
                            }
                            
                            {detail.blogtext2 && detail.blogtext2NextToImage === false &&
                                <BlogContentContainerSmall>
                                    <BlogContentContainer>
                                        <TextContainer>
                                            <TextParagraph>
                                                {parse(detail.blogtext2)}
                                            </TextParagraph>
                                        </TextContainer>
                                    </BlogContentContainer>
                                </BlogContentContainerSmall>
                            }
                            {detail.blogimage2[0] && detail.blogtext2NextToImage === false &&
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <RevealImage dataOffset="200">
                                            <Banner src={detail.blogimage2[0].url}/>
                                        </RevealImage>
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            }
                            {detail.caseyoutube2 ?
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <YoutubeEmbed data={detail.caseyoutube2} />
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            : null}
                            {detail.blogtiktok2 ?
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <TikTokEmbed data={detail.blogtiktok2} />
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            : null}
                            {detail.blogimage2[0] && detail.blogtext2NextToImage === true &&
                                <div className="grid grid-cols-2 image_text">
                                    <SplitImage className="col-span-2 lg:col-span-1">
                                        <CaseContainer>
                                            <RevealImage dataOffset="200">
                                                <BannerSide src={detail.blogimage2[0].url}/>
                                            </RevealImage>
                                        </CaseContainer>
                                    </SplitImage>
                                    <SplitText className="col-span-2 lg:col-span-1">
                                        <BlogContentContainer className="no_margin">
                                            <TextContainer>
                                                <TextParagraph>
                                                    {parse(detail.blogtext2)}
                                                </TextParagraph>
                                            </TextContainer>
                                        </BlogContentContainer> 
                                    </SplitText>
                                </div>
                            }

                            {detail.blogtext3 && detail.blogtext3NextToImage === false &&
                                <BlogContentContainerSmall>
                                    <BlogContentContainer>
                                        <TextContainer>
                                            <TextParagraph>
                                                {parse(detail.blogtext3)}
                                            </TextParagraph>
                                        </TextContainer>
                                    </BlogContentContainer>
                                </BlogContentContainerSmall>
                            }
                            {detail.blogimage3[0] && detail.blogtext3NextToImage === false &&
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <RevealImage dataOffset="200">
                                            <Banner src={detail.blogimage3[0].url}/>
                                        </RevealImage>
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            }
                            {detail.caseyoutube3 ?
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <YoutubeEmbed data={detail.caseyoutube3} />
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            : null}
                            {detail.blogtiktok2 ?
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <TikTokEmbed data={detail.blogtiktok2} />
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            : null}
                            {detail.blogimage3[0] && detail.blogtext3NextToImage === true &&
                                <div className="grid grid-cols-2 image_text">
                                    <SplitImage className="col-span-2 lg:col-span-1">
                                        <CaseContainer>
                                            <RevealImage dataOffset="200">
                                                <BannerSide src={detail.blogimage3[0].url}/>
                                            </RevealImage>
                                        </CaseContainer>
                                    </SplitImage>
                                    <SplitText className="col-span-2 lg:col-span-1">
                                        <BlogContentContainer className="no_margin">
                                            <TextContainer>
                                                <TextParagraph>
                                                    {parse(detail.blogtext3)}
                                                </TextParagraph>
                                            </TextContainer>
                                        </BlogContentContainer> 
                                    </SplitText>
                                </div>
                            }

                            {detail.blogtext4 && detail.blogtext4NextToImage === false &&
                                <BlogContentContainerSmall>
                                    <BlogContentContainer>
                                        <TextContainer>
                                            <TextParagraph>
                                                {parse(detail.blogtext4)}
                                            </TextParagraph>
                                        </TextContainer>
                                    </BlogContentContainer>
                                </BlogContentContainerSmall>
                            }
                            {detail.blogimage4[0] && detail.blogtext4NextToImage === false &&
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <RevealImage dataOffset="200">
                                            <Banner src={detail.blogimage4[0].url}/>
                                        </RevealImage>
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            }
                            {detail.caseyoutube4 ?
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <YoutubeEmbed data={detail.caseyoutube4} />
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            : null}
                            {detail.blogtiktok4 ?
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <TikTokEmbed data={detail.blogtiktok4} />
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            : null}
                            {detail.blogimage4[0] && detail.blogtext4NextToImage === true &&
                                <div className="grid grid-cols-2 image_text">
                                    <SplitImage className="col-span-2 lg:col-span-1">
                                        <CaseContainer>
                                            <RevealImage dataOffset="200">
                                                <BannerSide src={detail.blogimage4[0].url}/>
                                            </RevealImage>
                                        </CaseContainer>
                                    </SplitImage>
                                    <SplitText className="col-span-2 lg:col-span-1">
                                        <BlogContentContainer className="no_margin">
                                            <TextContainer>
                                                <TextParagraph>
                                                    {parse(detail.blogtext4)}
                                                </TextParagraph>
                                            </TextContainer>
                                        </BlogContentContainer> 
                                    </SplitText>
                                </div>
                            }

                            {detail.blogtext5 && detail.blogtext5NextToImage === false &&
                                <BlogContentContainerSmall>
                                    <BlogContentContainer>
                                        <TextContainer>
                                            <TextParagraph>
                                                {parse(detail.blogtext5)}
                                            </TextParagraph>
                                        </TextContainer>
                                    </BlogContentContainer>
                                </BlogContentContainerSmall>
                            }
                            {detail.blogtiktok5 ?
                                <BlogContentContainerSmall>
                                    <CaseContainer>
                                        <TikTokEmbed data={detail.blogtiktok5} />
                                    </CaseContainer>
                                </BlogContentContainerSmall>
                            : null}
                            </GlobalTextStyles>
                            <BlogContentContainerSmall>
                                <AuthorContainer>
                                    <ProfilePhoto src={ProfilePic}/>
                                    <AuthorContent>
                                        <AuthorName>{detail.author.name}</AuthorName>
                                        <BlogIndex>
                                            <BlogDate>{moment(detail.postDate).format('DD.MM.YY')} | </BlogDate>{detail.blogreadtime} min read</BlogIndex>
                                    </AuthorContent>
                                </AuthorContainer>
                            </BlogContentContainerSmall>
                        </BlogContentWrap>
                    </RevealOpacity>
                </SectionExtended>
            </div>
            <div className="col-span-12">
                <ArticleSection data={filteredArticles} title={relatedTitle} tags={detail.categoriesServices} location={data.location}/>
            </div>
        </div>
    )
}

export default ArticleDetailPage